import fancybox from "@fancyapps/fancybox";

import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';

// Initialize Fancybox
$.fancybox.defaults.loop = true;
$.fancybox.defaults.buttons = [
    "zoom",
    // 'share',
    "slideShow",
    "fullScreen",
    // 'download',
    "thumbs",
    "close",
];
$.fancybox.defaults.protect = true;
$.fancybox.defaults.transitionEffect = "slide";
$.fancybox.defaults.lang = "nl";
$.fancybox.defaults.i18n = {
    nl: {
        CLOSE: "Sluiten",
        NEXT: "Volgende",
        PREV: "Vorige",
        ERROR: "De aangevraagde inhoud kan niet worden geladen. <br/> Probeer het later opnieuw.",
        PLAY_START: "Start slideshow",
        PLAY_STOP: "Pauzeer slideshow",
        FULL_SCREEN: "Volledig scherm",
        THUMBS: "Thumbnails",
        DOWNLOAD: "Download",
        SHARE: "Delen",
        ZOOM: "Zoomen",
    },
};
